import { setRemoteDefinitions } from '@nrwl/angular/mf';

import { environment } from './environments/environment';

setRemoteDefinitions(
  Object.entries(environment.remotes).reduce<{ [key: string]: string }>(
    (pv, cv) => {
      pv[`micro-apps-${cv[0]}`] = cv[1];
      return pv;
    },
    {}
  )
);
import('./bootstrap').catch((err) => console.error(err));
