export const environment = {
  environment: "stage",
  syncfusionKey: 'ORg4AjUWIQA/Gnt2VVhjQlFac11JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0RjWH5acn1VT2VVWEc=',
  remotes: {
    dashboard: 'https://dashboard.stg.ekyc.knoxpo.com',
    contact: 'https://contact.stg.ekyc.knoxpo.com',
    kyc: 'https://kyc.stg.ekyc.knoxpo.com',
    client: 'https://client.stg.ekyc.knoxpo.com',
    business: 'https://business.stg.ekyc.knoxpo.com',
  },
};
